import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CreateWebshopInput from "../../components/CreateWebshopInput";
import OrgMerchantReviewForm from "../../components/OrgMerchantReviewForm";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";

import { useAxios } from "../../utils/hook/useAxios";

function AddMerchantReview() {
  let { website } = useParams();
  const [webshop, setWebshop] = useState(null);
  const [merchantID, setmerchantID] = useState(null);
  const { t, i18n } = useTranslation();
  const [date, setDate] = useState(new Date());

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const axios = useAxios();
  useEffect(() => {
    let fetchReviews = async () => {
      const response = await axios.get("/");
      const res = await axios.get("/auth/check-auth");
      setIsAuthenticated(res.data.isAuthenticated);
      // console.log(isAuthenticated);
    };
    fetchReviews();
  }, []);

  const handleWebshopSelection = (webshop) => {
    setWebshop(webshop.website);
    setmerchantID(webshop.id);
  };

  const handleConnect = (e) => {
    e.preventDefault();

    axios
      .post("/login", user, true)
      .then((res) => {
        if (res.data["success"] == true || res.data.token) {
          // console.log(res.data);
          // window.location.href = window.location.href;
          window.location.href = window.location.href; // Redirige vers la même URL
        } else {
          // console.log("Error to connect");
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error("Invalid password or username", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  function storeReturnToClient() {
    const currentUrl = window.location.href;

    // Stocker l'URL actuelle dans un cookie ou dans le stockage local
    // par exemple, en utilisant document.cookie ou window.localStorage
    // Assurez-vous de gérer correctement l'expiration et la sécurité des cookies.
    // Stocker l'URL actuelle dans le stockage local
    localStorage.setItem("returnTo", currentUrl);
  }
  function handleChangeConnect(e) {
    const { name, value } = e.target;
    setUser((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }
  const [values, setValues] = useState({
    title: "",
    content: "",
    experienceDate: date.toISOString().slice(0, 10),
  });
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  return (
    <>
      {!webshop && (
        <>
          {!isAuthenticated && (
            <div className="col-12 col-lg-6 mx-auto">
              <h3 className="text-center">
                {t("AddReviewForBetaTesterConnectToMsg")}
              </h3>
              <div className="d-flex flex-column flex-md-row gap-2 justify-content-center">
                <a
                  className="btn btn-primary"
                  style={{ backgroundColor: "#002d6b" }}
                  href="https://api.veritatrust.com/v1/auth/facebook/reviewform"
                >
                  {" "}
                  {t("SigninWithFacebook")}{" "}
                </a>

                <a
                  className="btn btn-primary"
                  style={{ backgroundColor: "#002d6b" }}
                  href={`https://api.veritatrust.com/v1/auth/google/reviewform?returnTo=${encodeURIComponent(
                    window.location.href
                  )}`}
                  onClick={() => storeReturnToClient()}
                >
                  {" "}
                  {t("SigninWithGoogle")}
                </a>

                <a href="#" className="btn btn-primary d-none">
                  Sign in with Apple
                </a>
                <div class="dropdown">
                  <button
                    type="button"
                    class="btn btn-primary dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                  >
                    {t("AddReviewForBetaTesterSignWithEmail")}
                  </button>
                  <div class="dropdown-menu dropdown-menu-end">
                    <form className="px-3" id="login" onSubmit={handleConnect}>
                      <div className="">
                        <div className="input-group">
                          <input
                            type="email"
                            className="form-control"
                            value={user.email}
                            onChange={handleChangeConnect}
                            name="email"
                            id="usermail"
                            aria-describedby="emailHelp"
                            title="User Email"
                            placeholder="User Email"
                          />
                          <span className="input-group-text bg-success text-white border-success">
                            <i className="flaticon-user"></i>
                          </span>
                        </div>
                        <div id="emailHelp" className="form-text mb-3"></div>
                        <div className="input-group mb-3 ">
                          <input
                            type="password"
                            className="form-control"
                            value={user.password}
                            onChange={handleChangeConnect}
                            id="userpassword"
                            name="password"
                            title="Password"
                            placeholder="Password"
                          />
                          <span className="input-group-text bg-success toggle-password text-white border-success">
                            <i className="flaticon-hide"></i>
                          </span>
                        </div>
                        <div className="mb-3 form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="rememberme"
                            checked={user.stayConnected && true}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="rememberme"
                          >
                            {t("HeaderNavbarConnected")}
                          </label>
                        </div>
                        <div className="d-grid gap-2">
                          <button
                            type="submit"
                            className="btn-block btn btn-success text-white"
                            id="userbutton"
                          >
                            {t("HeaderSignin")}
                          </button>
                        </div>
                      </div>
                    </form>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href={"/" + t("lang") + "/signup"}>
                      {t("AddReviewForBetaTesterNewAroundHere")}
                    </a>
                    <Link className="dropdown-item" to="/resetpassword">
                      {t("HeaderNavbarPass")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}{" "}
          <div
            className={
              isAuthenticated
                ? "mt-3 col-11 col-lg-8 py-4 px-lg-4 mx-auto bg-light rounded-5"
                : "mt-3 col-11 col-lg-8 py-4 px-lg-4 mx-auto bg-light rounded-5 blur-8"
            }
          >
            <CreateWebshopInput handleSelectWebshop={handleWebshopSelection} />
          </div>
        </>
      )}

      {webshop && (
        <OrgMerchantReviewForm
          website={webshop}
          textAreaId="content"
        ></OrgMerchantReviewForm>
      )}
    </>
  );
}

export default AddMerchantReview;
