import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AboutMerchant from "../../components/AboutMerchant";
import Filtering from "../../components/Filtering";
import MerchantDiv from "../../components/MerchantDiv";
import ServiceReview from "../../components/ServiceReview";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";

// import WriteReviewDiv from "../../components/WriteReviewDiv";
import { useAxios } from "../../utils/hook/useAxios";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import ReactPaginate from "react-paginate";

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((review, index) => (
          <div>
            <ServiceReview key={index} data={review} />
          </div>
        ))}
    </>
  );
}

function PaginationComponent({ itemsPerPage, items }) {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );

    // console.log(currentItems);
    setItemOffset(newOffset);
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.set("page", selectedPage + 1);
    const newUrl = window.location.pathname + "?" + queryParams.toString();
    window.history.pushState({}, "", newUrl);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      {currentItems.length > 5 && (
        <nav className="mt-5">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination pagination-lg justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            disabledLinkClassName="disabled"
            activeLinkClassName="active"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            forcePage={currentPage}
          />
        </nav>
      )}
    </>
  );
}

function ReviewMerchant(sharedValue, merchantProfileValue) {
  let { lang, website } = useParams();
  // const navigate = useNavigate();
  // const [stars, setStars] = useState(0);
  const [reviewsNumber, setReviewNumber] = useState(0);
  // const page = new URLSearchParams(window.location.search).get("page");
  const [reviews, setReviews] = useState([]);
  const [reviewsCopy, setReviewsCopy] = useState([]);
  const [ratings, setRatings] = useState("");
  const [MerchanData, setMerchanData] = useState(null);

  const [RM, setRM] = useState("");
  const axios = useAxios();
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang);

    axios
      .get(`/merchant_profiles/${website}`)
      .then((response) => {
        setMerchanData(response.data);
        axios
          .put(`/merchant_profiles/update/${response.data.id}`, {
            PageViewsNb: response.data.PageViewsNb + 1,
          })
          .then((response) => {})
          .catch((error) => {
            // Gérez les erreurs selon vos besoins
          });
      })
      .catch((error) => {
        // console.error("Erreur lors de la mise à jour du produit :", error);
        // Gérez les erreurs selon vos besoins
      });
  }, []);

  const [ratingProps, setRatingProps] = useState({
    excellent: 100,
    very_good: 0,
    good: 0,
    bad: 0,
    very_bad: 0,
  });

  const [AnswersNegativeReviewsNumber, setAnswersNegativeReviewsNumber] =
    useState(null);

  const [NegativeReviewsNumber, setNegativeReviewsNumber] = useState(null);

  useEffect(() => {
    let fetchReviews = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const ratings = urlParams.get("stars");
      //urlParams+= `?stars=${ratings}`;

      // console.log("website");
      // console.log(website);
      // setReviews(res.data);
      axios
        .get(`/organic-merchant-review/` + website + `?stars=${ratings || ""}`)
        .then((response) => {
          setReviews(response.data);
          setAnswersNegativeReviewsNumber(
            response.data.filter(
              (review) => review.rating <= 2 && review.isAnswered === "1"
            ).length
          );
          setNegativeReviewsNumber(
            response.data.filter((review) => review.rating <= 2).length
          );
          setReviewsCopy(response.data);
          setRatingProps(getRatingProprtion(response.data));
          if (response.data.length > 0) {
            setReviewNumber(response.data.length);
            setRM(response.data[0].RM);
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    };
    fetchReviews();
  }, [ratings]);

  function getRatingProprtion(datareviews) {
    let proportion = {
      excellent: 0,
      very_good: 0,
      good: 0,
      bad: 0,
      very_bad: 0,
    };

    for (var i = 0; i < datareviews.length; i++) {
      switch (datareviews[i].rating) {
        case 5:
          proportion.excellent = proportion.excellent + 1;
          break;
        case 4:
          proportion.very_good = proportion.very_good + 1;
          break;
        case 3:
          proportion.good = proportion.good + 1;
          break;
        case 2:
          proportion.bad = proportion.bad + 1;
          break;
        case 1:
          proportion.very_bad = proportion.very_bad + 1;
          break;
        default:
      }
    }

    proportion.excellent =
      ((proportion.excellent * 100) / datareviews.length) | 0;
    proportion.very_good =
      ((proportion.very_good * 100) / datareviews.length) | 0;
    proportion.good = ((proportion.good * 100) / datareviews.length) | 0;
    proportion.bad = ((proportion.bad * 100) / datareviews.length) | 0;
    proportion.very_bad =
      ((proportion.very_bad * 100) / datareviews.length) | 0;

    return proportion;
  }

  const [selectedLanguage, setSelectedLanguage] = useState("");
  useEffect(() => {
    // Your useEffect logic here that should be triggered when selectedLanguage changes
    const reviews_copy = reviewsCopy;
    setReviews(
      reviews_copy.filter((review) => review.lang_id == selectedLanguage)
    );

    if (selectedLanguage === "") {
      setReviews(reviewsCopy);
    }
    updateUrlWithLangId(selectedLanguage);
  }, [selectedLanguage]);

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
  };

  function updateUrlWithLangId(langId) {
    // Récupérer l'URL actuelle
    let currentUrl = window.location.href;

    // Créer un nouvel objet URLSearchParams pour gérer les paramètres
    const urlParams = new URLSearchParams(window.location.search);

    // Mettre à jour ou ajouter le paramètre lang_id
    if (langId) {
      urlParams.set("lang_id", langId);
    } else {
      // Si langId est vide, supprimer le paramètre lang_id s'il existe
      urlParams.delete("lang_id");
    }

    // Construire la nouvelle URL
    const paramString = urlParams.toString();
    const newUrl =
      currentUrl.split("?")[0] + (paramString ? "?" + paramString : "");

    // Remplacer l'URL actuelle avec la nouvelle
    window.history.replaceState({ path: newUrl }, "", newUrl);
  }

  // Exemple d'utilisation

  return (
    <>
      <MerchantDiv
        propsRM={RM}
        sharedValue={sharedValue}
        merchantProfileValue={merchantProfileValue}
      />

      <section className="bg-success profil-main">
        <div className="container py-2 py-lg-5">
          <div className="row">
            <div className="col-lg-8" style={{ zIndex: 100 }}>
              {/* <WriteReviewDiv website={website}></WriteReviewDiv> */}
              <Filtering
                onFilterChange={(ratings) => setRatings(ratings)}
                PropsPropotion={ratingProps}
                onLanguageChange={handleLanguageChange}
              />

              <div className="reviews-list">
                <div>
                  <PaginationComponent
                    itemsPerPage={5}
                    items={reviews}
                  ></PaginationComponent>
                </div>
              </div>
            </div>

            <div className="col-lg-4 text-end">
              <div
                class="bg-white px-3 py-1 p-lg-3 mb-3 rounded-5 shadow widget enterprise aos animated aos-init aos-animate"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
              >
                <div class="header d-flex">
                  <h4>{t("MerchantProfileCompanyOverview")}</h4>
                  {/* <p>
                    <a href="#">Tout afficher</a>
                  </p> */}
                </div>
                <div class="content">
                  <ul class="ps-0">
                    {MerchanData && MerchanData.isClaimed && (
                      <li class="d-flex">
                        <div class="icon">
                          {<i class="flaticon-check text-success"></i>}
                        </div>
                        <div class="text">
                          {t("MerchantProfileClaimedProfile")}
                        </div>
                      </li>
                    )}
                    <li class="d-flex">
                      <div class="icon">
                        <i class="flaticon-messager"></i>
                      </div>
                      <div class="text">
                        {t("MerchantProfileAnswerReviewNumber", {
                          AnswerReviewNumber: AnswersNegativeReviewsNumber,
                          NegativeReviews: NegativeReviewsNumber,
                        })}
                      </div>
                    </li>
                    <li class="d-flex">
                      <div class="icon">
                        <i class="flaticon-clock"></i>
                      </div>
                      <div class="text">
                        {t("MerchantProfileTiming", { WeekNumbers: 1 })}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <AboutMerchant title={t("ReviewMerchantPageAbout") + website} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => ({
  sharedValue: state.auth.sharedValue,
  merchantProfileValue: state.auth.merchantProfileValue,
});

export default connect(mapStateToProps)(ReviewMerchant);
