import React, { useEffect, useState } from "react";
import DashboardDiv from "../../components/DashboardDiv";
import LastReview from "../../components/LastReview";
import NavList from "../../components/NavList";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";
//import AOS from "aos";
//import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((review, index) => (
          <LastReview key={index} data={review} index={index} />
        ))}
    </>
  );
}

function PaginationComponent({ itemsPerPage, items }) {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  //.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;

    //.log(currentItems);
    setItemOffset(newOffset);
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.set("page", selectedPage + 1);
    const newUrl = window.location.pathname + "?" + queryParams.toString();
    window.history.pushState({}, "", newUrl);
  };
  const { t } = useTranslation();

  return (
    <>
      <table className="table mb-3">
        <thead>
          <tr>
            <th className="review-date" scope="col">
              <p className="mb-0">{t("AccountReviewsDate")}</p>
            </th>
            <th className="review-details" scope="col">
              <p className="mb-0">{t("AccountReviewsDetails")}</p>
            </th>
            {/* <th className="review-reward" scope="col">
              <p className="mb-0">{t("AccountReviewsNft")}</p>
            </th> */}
            <th className="review-reward" scope="col">
              <p className="mb-0">{t("AccountReviewsReward")}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <Items currentItems={currentItems} />
        </tbody>
      </table>
      {currentItems.length > 10 && (
        <nav className="mt-5">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination pagination-lg justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            disabledLinkClassName="disabled"
            activeLinkClassName="active"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            forcePage={currentPage}
          />
        </nav>
      )}
    </>
  );
}

function DashboardReviewsPage() {
  const axios = useAxios();
  const page = new URLSearchParams(window.location.search).get("page");
  const [stars, setStars] = useState(0);
  const [nb, setNb] = useState(0);
  // const location = useLocation();
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   AOS.init({
  //     duration: 1000,
  //   });
  // }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setStars(params.get("stars"));
  }, []);

  const [reviews, setReviews] = useState([]);

  const [UserRewardData, setUserRewardData] = useState({
    fName: "",
    lName: "",
    totalReward: 0,
    approuvedRaward: 0,
    pendingReward: 0,
    levelAccount: 1,
    fLevelAccount: 2,
    reviewNb: 21,
  });

  useEffect(() => {
    let fetchReviews = async () => {
      let response = await axios.get("/");

      if (window.localStorage.getItem("userdata")) {
        const retrievedObject = JSON.parse(
          window.localStorage.getItem("userdata")
        );
        response.data = retrievedObject;
      }
      const res = await axios.get("/lreview-dashboard/" + response.data["id"]);

      const reviewsPerPage = 10; // Nombre de reviews à afficher par page
      const startIndex = page > 0 ? (page - 1) * reviewsPerPage : 0; // Indice de départ pour la pagination

      if (startIndex >= res.data.length) {
        // Gérer le cas où l'indice de départ est supérieur ou égal à la longueur des données
        setReviews([]);
      } else {
        const endIndex = startIndex + reviewsPerPage; // Indice de fin pour la pagination
        setReviews(res.data);
      }
      //.log(reviews);

      if (res.data.length > 0) {
        const rewardData = {
          fName: res.data[0].first_name,
          lName: res.data[0].last_name,
          totalReward: res.data[0].total,
          approuvedRaward: res.data[0].total_published,
          pendingReward: res.data[0].total_pending,
          levelAccount: res.data[0].level_account,
          fLevelAccount: res.data[0].level_account + 1,
          reviewNb: res.data.length,
        };

        setUserRewardData(rewardData);
        setNb(res.data.length);
        //.log(UserRewardData);
      }
    };
    fetchReviews();
  }, []);

  return (
    <>
      <DashboardDiv titlepage={t("AccountReviewsTitle")} />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavList></NavList>
                </div>
                <div className="dashboard col-12 col-lg-9">
                  <div
                    className="mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <h4 className="lead">{t("AccountReviewsTitle")}</h4>

                    <PaginationComponent
                      itemsPerPage={10}
                      items={reviews}
                    ></PaginationComponent>
                    {reviews.length === 0 && (
                      <div className="callout callout-info">
                        <p className="mb-0">
                          {t("AccountReviewsReviewsNotYet")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DashboardReviewsPage;
