import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useParams } from "react-router-dom";
import CategoriesDiv from "../../components/CategoriesDiv";
import NavCategories from "../../components/NavCategories";
import ProductMerchantItem from "../../components/ProductMerchantItem";
import { useAxios } from "../../utils/hook/useAxios";
// import "./CustomCarousel.css"; // Import du fichier CSS personnalisé
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";

const PAGINATION_NUMBER = 20;

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((review, index) => (
          <div>
            <ProductMerchantItem key={index} propsData={review} />
          </div>
        ))}
    </>
  );
}

function PaginationComponent({ itemsPerPage, items }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );

    // console.log(currentItems);
    setItemOffset(newOffset);
    const selectedPage = event.selected;
    setCurrentPage(selectedPage);
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.set("page", selectedPage + 1);
    const newUrl = window.location.pathname + "?" + queryParams.toString();
    window.history.pushState({}, "", newUrl);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      {currentItems.length > PAGINATION_NUMBER && (
        <nav className="mt-5">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination pagination-lg justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            disabledLinkClassName="disabled"
            activeLinkClassName="active"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            forcePage={currentPage}
          />
        </nav>
      )}
    </>
  );
}

function CategoriesPage() {
  const location = useLocation();
  const categorydata = location.pathname;
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang, category } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  let categoryId = 0;

  if (category) categoryId = categorydata.split("/")[3].split("-")[0]; //useParams();

  //const category = categorydata.toString().split("-")[1];
  const [activeCategory, setActiveCategory] = useState("");
  const [resultNumber, setResultNumber] = useState(0);
  const [results, setResults] = useState([]);
  const [resultsCopy, setResultsCopy] = useState([]);
  const [brand_names, setBrand_names] = useState([]);
  const [topcategories, setTopcategories] = useState([]);
  const [topBrands, setTopBrands] = useState([]);

  const axios = useAxios();
  const [centerSlidePercentage, setCenterSlidePercentage] = useState(60);
  const [nameCategory, setNameCategory] = useState(t("CategoriePageTitle"));
  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    variableWidth: false,
    centerMode: true,
    swipeToSlide: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    i18n.changeLanguage(lang);

    axios.get("/data/categories/getpopular/topcategories").then((res) => {
      if (window.localStorage.getItem("langPage") === "en")
        setTopcategories(res.data.map((objet) => objet.category_name));
      else {
        setTopcategories(
          res.data.map(
            (objet) =>
              objet[`category_name_${window.localStorage.getItem("langPage")}`]
          )
        );
      }
    });

    axios
      .get("/brands/search/topbrands?category_id=" + categoryId)
      .then((res) => {
        setTopBrands(res.data);
      });

    window.scrollTo(0, 0);
  }, [, t("lang")]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const brandIdParam = searchParams.get("brand")?.split("-")[0];
    // console.log("brandparam", brandIdParam);

    if (brandIdParam) {
      setResults(resultsCopy.filter((item) => item.Brand_id == brandIdParam));
      const a = resultsCopy.filter((item) => item.Brand_id == brandIdParam);
      setResultNumber(a.length);
    }
  }, [, location.search]);

  useEffect(() => {
    if (category) {
      const catArray = category.split("-");
      const lastIndex = catArray.length - 1;
      setActiveCategory(catArray[lastIndex]);
    }

    if (category) {
      axios.get("/search-categories-items/" + categoryId).then((response) => {
        const GroupIds = response.data.map(
          (category) => category.google_category_id
        );
        // Create a tree of categories

        const GroupsIdsStr = GroupIds.join(",");

        axios
          .get("/search-by-category-parent-id/" + GroupsIdsStr)
          .then((res) => {
            // Create a tree of categories
            setResultNumber(res.data.length);
            setResults(res.data);
            setResultsCopy(res.data);

            setIsLoading(false);

            const fetchBrandNames = async () => {
              const base_url = "/brands/";

              const promises = res.data.map(async (objet) => {
                const brand_id = objet.Brand_id;
                const url = `${base_url}${brand_id}`;

                try {
                  const response = await axios.get(url);
                  const brand_name = response.data.Brand_name;
                  return { brand_name: brand_name, brand_id: brand_id };
                } catch (error) {
                  // console.error(
                  //   `Erreur lors de la requête pour Brand_id ${brand_id}:`,
                  //   error.message
                  // );
                  return "Erreur de requête";
                }
              });

              let brandNames = await Promise.all(promises);
              const uniqueBrandIds = new Set();
              brandNames = brandNames.filter((item) => {
                const isUnique = !uniqueBrandIds.has(item.brand_id);
                uniqueBrandIds.add(item.brand_id);
                return isUnique;
              });
              setBrand_names(brandNames);
              // console.log(brandNames);
            };

            // Appel de la fonction pour effectuer la requête
            fetchBrandNames();
          });
      });
    } else {
      axios
        .get(
          "/search-by-category-parent-id/1,8,412,1239,436,772,469,783,2092,111,888,9222,5181,141,166,636,537,988,222,632"
        )
        .then((res) => {
          // Create a tree of categories
          setResultNumber(res.data.length);
          setResults(res.data);
          setResultsCopy(res.data);

          setIsLoading(false);

          const fetchBrandNames = async () => {
            const base_url = "/brands/";

            const promises = res.data.map(async (objet) => {
              const brand_id = objet.Brand_id;
              const url = `${base_url}${brand_id}`;

              try {
                const response = await axios.get(url);
                const brand_name = response.data.Brand_name;
                return { brand_name: brand_name, brand_id: brand_id };
              } catch (error) {
                // console.error(
                //   `Erreur lors de la requête pour Brand_id ${brand_id}:`,
                //   error.message
                // );
                return "Erreur de requête";
              }
            });

            let brandNames = await Promise.all(promises);
            const uniqueBrandIds = new Set();
            brandNames = brandNames.filter((item) => {
              const isUnique = !uniqueBrandIds.has(item.brand_id);
              uniqueBrandIds.add(item.brand_id);
              return isUnique;
            });
            setBrand_names(brandNames);
            // console.log(brandNames);
          };

          // Appel de la fonction pour effectuer la requête
          fetchBrandNames();
        });
    }

    axios.get("/data/categories/get-category-id/" + categoryId).then((res) => {
      if (
        res.data.google_category_id == 1 ||
        res.data.google_category_id == 537 ||
        res.data.google_category_id == 111 ||
        res.data.google_category_id == 783 ||
        res.data.google_category_id == 1604 ||
        res.data.google_category_id == 1445 ||
        res.data.google_category_id == 469 ||
        res.data.google_category_id == 141 ||
        res.data.google_category_id == 1300 ||
        res.data.google_category_id == 1029 ||
        res.data.google_category_id == 2528 ||
        res.data.google_category_id == 1239
      )
        setNameCategory(res.data.vt_category);
      else
        setNameCategory(
          res.data[
            `category_name_${window.localStorage.getItem("langPage")}`
          ] || res.data.category_name
        );
      //  else setNameCategory(res.data.category_name);
    });
  }, [category]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCenterSlidePercentage(40); // Pour les écrans de taille téléphone
      } else {
        setCenterSlidePercentage(15); // Pour les écrans de taille bureau
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Appel initial pour définir la valeur en fonction de la taille de l'écran actuelle

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [selectedItem, setSelectedItem] = useState(""); // État local pour suivre l'élément sélectionné
  const handleItemClick = (item) => {
    // Mettez à jour l'état local avec l'élément sélectionné
    setSelectedItem(item);
    if (item == "mostReviewed")
      setResults(results.sort((a, b) => b.ReviewsNumber - a.ReviewsNumber));
    else if (item == "highestRating") {
      setResults(results.sort((a, b) => b.ReviewMean - a.ReviewMean));
    } else if (item == "mostPopular") {
      setResults(results.sort((a, b) => b.PageViewsNb - a.PageViewsNb));
    }
  };

  // Générez le message en fonction de l'élément sélectionné
  const getMessage = () => {
    switch (selectedItem) {
      case "mostPopular":
        return t("CategoriePageMostPopular");

      case "mostReviewed": {
        return t("CategoriePageMostReviewed");
      }

      case "highestRating": {
        return t("CategoriePageHighestRating");
      }

      default:
        return t("CategoriePageRelevance");
    }
  };

  const [ParentClicked, setParentClicked] = useState(null);

  return (
    <>
      <CategoriesDiv
        // subpage={category ? nameCategory : t("CategoriePageTitle")}
        subpage={category ? nameCategory : t("CategoriePageTitle")}
      />
      <section className="bg-success categories">
        <div className="container py-lg-5 py-2">
          <div className="row mb-4">
            <div className="col-lg-8 order-lg-2 search_results">
              <div className="d-lg-flex justify-content-between align-items-center">
                <h3 className="text-white">Top {t("CategoriePageTitle")}</h3>
              </div>
              <div className="custom-carousel-container">
                {" "}
                {/* Ajout de la classe personnalisée */}
                <Slider {...settings}>
                  {topcategories.map((item, index) => (
                    <a key={index} className="btn btn-light w-100" href="#">
                      <h3 className="h6">{item}</h3>
                    </a>
                  ))}
                </Slider>
              </div>
              <div className="d-lg-flex justify-content-between align-items-center">
                {resultNumber > 0 && (
                  <h3 className="text-white">
                    {resultNumber} {t("CategoriePageResultsIn")} {nameCategory}
                  </h3>
                )}
                <div
                  class="btn-group mb-2 d-flex filter_category_mobile"
                  role="group"
                >
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle btn-sm"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {getMessage()}
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <Link
                        className="dropdown-item"
                        to="#mostPopular"
                        onClick={() => handleItemClick("mostPopular")}
                      >
                        {t("CategoriePageMostPopular")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="#mostReviewed"
                        onClick={() => handleItemClick("mostReviewed")}
                      >
                        {t("CategoriePageMostReviewed")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="#highestRating"
                        onClick={() => handleItemClick("highestRating")}
                      >
                        {t("CategoriePageHighestRating")}
                      </Link>
                    </li>
                  </ul>
                  <button
                    class="btn btn-primary d-lg-none btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#overlays-filter"
                  >
                    All categories <i class="flaticon-menu"></i>
                  </button>
                </div>
              </div>
              {isLoading ? (
                <></>
              ) : (
                <PaginationComponent
                  itemsPerPage={PAGINATION_NUMBER}
                  items={results}
                ></PaginationComponent>
              )}
              <h4 className="text-white">
                Most popular brands in {nameCategory}
              </h4>
              <div className="custom-carousel-container">
                <Slider {...settings}>
                  {topBrands &&
                    topBrands.map((item, index) => (
                      <a key={index} className="btn btn-light w-100" href="#">
                        <h3 className="h6">{item.Brand_name}</h3>
                      </a>
                    ))}
                </Slider>
              </div>
            </div>
            <div className="col-lg-4 order-lg-1 d-none d-lg-block">
              <div className="bg-white p-3 mb-3 rounded-5 shadow widget enterprise">
                <div class="header">
                  <a
                    class="d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse"
                    href="#subcategories"
                    role="button"
                    aria-expanded="true"
                    aria-controls="subcategories"
                  >
                    <h4>{t("CategoriePageTitle")}</h4>
                    {t("CategoriePageHide")}
                  </a>
                </div>
                <div className="content collapse show" id="subcategories">
                  <nav>
                    <ul className="list-unstyled user-menu">
                      <NavCategories
                        proposId="subcategory1"
                        categoryParentId={1}
                        nameCat={t("AddReviewForBetaTesterCategoryAnimals")}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t("AddReviewForBetaTesterCategoryChilcare")}
                        proposId="subcategory537"
                        categoryParentId={"537"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t("AddReviewForBetaTesterCategoryCompanies")}
                        proposId="subcategory111"
                        categoryParentId={"922,111"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t("AddReviewForBetaTesterCategoryCulture")}
                        proposId="subcategory783"
                        categoryParentId={"783"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t(
                          "AddReviewForBetaTesterCategoryFashionAccess"
                        )}
                        proposId="subcategory5181"
                        categoryParentId={"5181, 166"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t(
                          "AddReviewForBetaTesterCategoryFoodsAndDrunk"
                        )}
                        proposId="subcategory412"
                        categoryParentId={"412"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t(
                          "AddReviewForBetaTesterCategoryHealthBeauty"
                        )}
                        proposId="subcategory469"
                        categoryParentId={"469"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>

                      <NavCategories
                        nameCat={t("AddReviewForBetaTesterCategoryHighTech")}
                        proposId="subcategory141"
                        categoryParentId={"141, 222, 2092"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t("AddReviewForBetaTesterCategoryHomeDIY")}
                        proposId="subcategory436"
                        categoryParentId={"436,632,536"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t(
                          "AddReviewForBetaTesterCategorySportLeisure"
                        )}
                        proposId="subcategory8"
                        categoryParentId={8}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>

                      <NavCategories
                        nameCat={t("AddReviewForBetaTesterCategoryVehicles")}
                        proposId="subcategory888"
                        categoryParentId={"888"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t("AddReviewForBetaTesterCategoryVideogames")}
                        proposId="subcategory1239"
                        categoryParentId={"1239"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="bg-white p-3 mb-3 rounded-5 shadow widget enterprise">
                <div class="header">
                  <a
                    class="d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse"
                    href="#maker"
                    role="button"
                    aria-expanded="true"
                    aria-controls="maker"
                  >
                    <h4>Brands</h4>
                    {t("CategoriePageHide")}
                  </a>
                </div>
                <div id="maker" className="content collapse show">
                  <nav>
                    <ul className="list-unstyled sub-category">
                      {brand_names.map((item, index) => (
                        <li key={index}>
                          <Link
                            to={
                              "?brand=" + item.brand_id + "-" + item.brand_name
                            }
                            className={
                              location.search.includes(item.brand_name)
                                ? "active"
                                : ""
                            }
                          >
                            {item.brand_name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        id="overlays-filter"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="Filter"
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="">Filter</h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body bg-success">
              <div className="bg-white p-3 mb-3 rounded-5 shadow widget enterprise">
                <div class="header">
                  <a
                    class="d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse"
                    href="#subcategories"
                    role="button"
                    aria-expanded="true"
                    aria-controls="subcategories"
                  >
                    <h4>{t("CategoriePageTitle")}</h4>
                    {t("CategoriePageHide")}
                  </a>
                </div>
                <div className="content collapse show" id="subcategories">
                  <nav>
                    <ul className="list-unstyled user-menu">
                      <NavCategories
                        proposId="subcategory1"
                        categoryParentId={1}
                        nameCat={t("AddReviewForBetaTesterCategoryAnimals")}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t("AddReviewForBetaTesterCategoryChilcare")}
                        proposId="subcategory537"
                        categoryParentId={"537"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t("AddReviewForBetaTesterCategoryCompanies")}
                        proposId="subcategory111"
                        categoryParentId={"922,111"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t("AddReviewForBetaTesterCategoryCulture")}
                        proposId="subcategory783"
                        categoryParentId={"783"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t(
                          "AddReviewForBetaTesterCategoryFashionAccess"
                        )}
                        proposId="subcategory5181"
                        categoryParentId={"5181, 166"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t(
                          "AddReviewForBetaTesterCategoryFoodsAndDrunk"
                        )}
                        proposId="subcategory412"
                        categoryParentId={"412"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t(
                          "AddReviewForBetaTesterCategoryHealthBeauty"
                        )}
                        proposId="subcategory469"
                        categoryParentId={"469"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>

                      <NavCategories
                        nameCat={t("AddReviewForBetaTesterCategoryHighTech")}
                        proposId="subcategory141"
                        categoryParentId={"141, 222, 2092"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t("AddReviewForBetaTesterCategoryHomeDIY")}
                        proposId="subcategory436"
                        categoryParentId={"436,632,536"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t(
                          "AddReviewForBetaTesterCategorySportLeisure"
                        )}
                        proposId="subcategory8"
                        categoryParentId={8}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>

                      <NavCategories
                        nameCat={t("AddReviewForBetaTesterCategoryVehicles")}
                        proposId="subcategory888"
                        categoryParentId={"888"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                      <NavCategories
                        nameCat={t("AddReviewForBetaTesterCategoryVideogames")}
                        proposId="subcategory1239"
                        categoryParentId={"1239"}
                        ParentClicked={ParentClicked}
                        setParentClicked={setParentClicked}
                      ></NavCategories>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoriesPage;
