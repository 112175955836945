import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import AddReviewDiv from "../../components/AddReviewDiv";
import SearchProduct from "../../components/SearchProduct";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function AddNewReviewPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [productFilter, setProductFilter] = useState(true);
  const [merchantFilter, setMerchantFilter] = useState(true);
  const NumberOfListingBloc = 10;
  const axios = useAxios();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    // Send a request to the server to search for reviews matching the search term
    if (searchTerm.length >= 3) {
      axios
        .get(`/search?q=${searchTerm}`)
        .then((response) => {
          setSearchResults(response.data);
        })
        .catch((error) => {
          //.error(error);
        });
    } else {
      setSearchResults([]);
    }
    //.log("searchterm.length: ", searchTerm.length);
  }, [searchTerm]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);

    AOS.init({
      duration: 1000,
    });
  }, []);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleProductFilterChange = () => {
    setProductFilter(!productFilter);
  };

  const handleMerchantFilterChange = () => {
    setMerchantFilter(!merchantFilter);
  };

  return (
    <>
      <AddReviewDiv titlepage={t("AddreviewTitle")} />
      <section className="bg-success categories">
        <div className="container py-lg-5 py-2">
          <div className="row mb-4">
            <div className="col-xl-8 order-lg-2 search_results mx-auto">
              <div class="d-lg-flex justify-content-between align-items-center">
                <h3 class="text-white my-3">
                  <strong>{t("AddreviewSubTitle")}</strong>
                </h3>
              </div>
              <form class="search mb-3" role="search" autocomplete="off">
                <input
                  type="search"
                  class="form-control"
                  placeholder={t("AddreviewSearchField")}
                  name="q"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <fieldset class="row my-2 text-white">
                  <legend class="col-form-label col-2 pt-0">
                    {t("AddreviewSearchFieldFilter")}
                  </legend>
                  <div class="col-10">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="productFilter"
                        value="product"
                        checked={productFilter}
                        onChange={handleProductFilterChange}
                      />
                      <label class="form-check-label" for="productFilter">
                        {t("AddreviewSearchFieldFilterProduct")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="merchantFilter"
                        value="merchant"
                        checked={merchantFilter}
                        onChange={handleMerchantFilterChange}
                      />
                      <label class="form-check-label" for="merchantFilter">
                        {t("AddreviewSearchFieldFilterMerchant")}
                      </label>
                    </div>
                  </div>
                </fieldset>
              </form>
              <div class="no-result text-white d-none">
                <h3 class="">
                  <strong>Sorry, no results for</strong> “web-horn”.
                </h3>
                <p class="h4">
                  <i class="flaticon-information"></i>{" "}
                  <strong>Tips to get results:</strong>
                </p>
                <ul>
                  <li>Type general keywords (brand name, product type ...)</li>
                  <li>Verify the spelling or try other spellings</li>
                </ul>
              </div>

              <div class="result-empty text-white">
                <p class="h4">
                  <strong>{t("AddreviewHelpHowToTitle")}</strong>
                </p>
                <ol class="">
                  <li>{t("AddreviewHelpHowToStep1")}</li>
                  <li>{t("AddreviewHelpHowToStep2")}</li>
                  <li>{t("AddreviewHelpHowToStep3")}</li>
                </ol>{" "}
              </div>
              <div class="listing">
                <p class="badge bg-warning d-none">
                  Warning for DEV : List of 20 last reviews by pages
                </p>
                {/* <!-- Start Boucle produits --> */}
                <div class="results search">
                  {/* {searchResults &&
                    searchResults.map((product, index) => (
                      <SearchProduct
                        key={index}
                        product_name={product.product_name}
                        product_id={product.id}
                        type={product.type}
                        propsNbReviews={product.ReviewsNumber}
                        propsRM={product.ReviewMean}
                        propsLocation="add-review"
                      />
            
                    ))} */}
                  {searchResults &&
                    searchResults
                      .filter(
                        (product) =>
                          (productFilter && product.type === "product") ||
                          (merchantFilter && product.type === "merchant")
                      )
                      .map((product, index) => {
                        if ((index + 1) % NumberOfListingBloc === 0) {
                          return (
                            <React.Fragment key={index}>
                              <SearchProduct
                                key={index}
                                product_name={product.product_name}
                                product_id={product.id}
                                type={product.type}
                                propsNbReviews={product.ReviewsNumber}
                                propsRM={product.ReviewMean}
                                propsLocation="add-review"
                                propsLang={t("lang")}
                              />
                              <div
                                class="card mb-2 mb-lg-4 aos animated aos-init aos-animate"
                                data-aos="fade-up"
                                data-aos-anchor-placement="center-bottom"
                              >
                                <div class="card-body">
                                  <h5 class="card-title">
                                    {t("AddreviewBottomHowToTitle")}{" "}
                                  </h5>
                                  <p>{t("AddreviewBottomHowToMessage")}</p>
                                  <Link
                                    to="/add-new-review"
                                    class="btn btn-success btn-sm w-100"
                                  >
                                    {t("AddreviewBottomHowToButton")}
                                  </Link>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <SearchProduct
                              key={index}
                              product_name={product.product_name}
                              product_id={product.id}
                              type={product.type}
                              propsNbReviews={product.ReviewsNumber}
                              propsRM={product.ReviewMean}
                              propsLocation="add-review"
                            />
                          );
                        }
                      })}

                  {searchTerm.length > 0 && (
                    <div
                      class="card mb-2 mb-lg-4 aos animated aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-anchor-placement="center-bottom"
                    >
                      <div class="card-body">
                        <h5 class="card-title">
                          {t("AddreviewBottomHowToTitle")}{" "}
                        </h5>
                        <p>
                          <p>{t("AddreviewBottomHowToMessage")}</p>
                        </p>
                        <Link
                          to="/add-new-review"
                          class="btn btn-success btn-sm w-100 mb-2"
                        >
                          {t("AddreviewBottomHowToButton")}
                        </Link>
                        <Link
                          to="/add-merchant-review"
                          class="btn btn-primary btn-sm w-100"
                        >
                          {t("AddmerchantreviewBottomHowToButton")}
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddNewReviewPage;
