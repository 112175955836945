import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import portrait from "../../assets/lorem-portrait.jpg";
import reviewsHomepage from "../../assets/reviews-homepage.jpg";
import Categories from "../../components/Categories";
import ScrollReview from "../../components/ScrollReview";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/owl.carousel.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

function Home() {
  const axios = useAxios();
  const [lastreviews, setLastreviews] = useState([]);
  const [lastreviews2, setLastreviews2] = useState([]);
  const [centerSlidePercentage, setCenterSlidePercentage] = useState(60);
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const { lang } = useParams();

  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 4000,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const events = {
  //   onDragged: () => console.log("dragged"),
  //   onChanged: () => console.log("changed"),
  // };
  useEffect(() => {
    i18n.changeLanguage(lang);
    if (window.location.hash && window.location.hash === "#_=_") {
      window.location.hash = "";
    }
  }, []);

  useEffect(() => {
    // Vérifier si la connexion WebSocket est établie
    // socket.on("connect", () => {
    //   console.log("WebSocket Client Connected successfully lorem ipsum ");
    //   console.log("Socket ID:", socket.id);
    //   // Écouter l'événement "notification" pour recevoir les notifications
    //   socket.on("reviews", (review) => {
    //     setLastreviews((prevNotifications) => [review, ...prevNotifications]);
    //     console.log(review);
    //   });
    // });

    axios.get("/lastreview-caroussel").then((res) => {
      setLastreviews(res?.data.slice(0, 11));
      setLastreviews2(res?.data.slice(12, 23));
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCenterSlidePercentage(75); // Pour les écrans de taille téléphone
      } else {
        setCenterSlidePercentage(15); // Pour les écrans de taille bureau
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Appel initial pour définir la valeur en fonction de la taille de l'écran actuelle

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <section className="py-5 home__hero">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 py-5">
              {/* <h1 className="display-2">
                <span className="text-primary">{t("HomepageH1")}</span>
              </h1> */}
              <h1 className="display-2">
                <Trans
                  i18nKey="HomepageH1"
                  components={{
                    1: <span className="text-primary"></span>,
                    2: <span></span>,
                  }}
                />
              </h1>
              <p className="lead">{t("HomepageBaseline")}</p>
              <p className="lead text-green">{t("HomepageBaseline2")} </p>
              <Link
                className="btn btn-success me-4 text-white px-5"
                to={"/" + window.localStorage.getItem("langPage") + "/c"}
              >
                {t("HomepageExplore")}{" "}
              </Link>
              <Link
                className="btn btn-outline-primary px-5"
                to={`/${window.localStorage.getItem("langPage")}/add-review`}
              >
                {t("HomepageCreate")}{" "}
              </Link>
            </div>
            <div className="col-lg-6 py-5">
              <img src={reviewsHomepage} alt="reviews" />
            </div>
          </div>
        </div>
      </section>
      <Categories></Categories>

      <section className="home__reviews py-5">
        <div className="container">
          <div className="row mb-4">
            <h4 className="display-6 text-success">{t("HomeRecentReviews")}</h4>
          </div>
        </div>

        <div className="">
          <div className="mb-4">
            <>
              {/* <Slider {...settings}>
                {lastreviews &&
                  lastreviews.map((review, index) => (
                    <div className="px-2" key={index}>
                      <div className="item">
                        {index % 2 === 0 ? (
                          <>
                            <ScrollReview data={review} />
                            {index + 1 < lastreviews.length && (
                              <ScrollReview data={lastreviews[index + 1]} />
                            )}
                          </>
                        ) : (
                          <>
                            {index - 1 >= 0 && (
                              <ScrollReview data={lastreviews[index - 1]} />
                            )}
                            <ScrollReview data={review} />
                          </>
                        )}
                      </div>
                    </div>
                  ))}
              </Slider> */}
              <Slider {...settings}>
                {lastreviews &&
                  lastreviews.map((review, index) => {
                    if (index % 2 !== 0) return null; // On saute les index impairs car ils seront affichés en pair avec l'index précédent
                    return (
                      <div className="px-2" key={index}>
                        <div className="item">
                          <ScrollReview data={review} />
                          {index + 1 < lastreviews.length && (
                            <ScrollReview data={lastreviews[index + 1]} />
                          )}
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </>
          </div>
        </div>
        <div className="col-lg-6 mx-auto d-none">
          <p className="lead text-center">
            {t("contentText")}
            <br />
            {t("contentText")}
          </p>
          <a className="btn btn-primary w-100" href="#">
            {t("contentText")}
          </a>
        </div>
      </section>

      <section className="home__logos bg-success py-3 d-none">
        <div className="container">
          <div className="row">
            <div className="col-6 col-lg-2 text-center mb-3">
              <img className="" src={portrait} alt="" height="60" width="60" />
            </div>
            <div className="col-6 col-lg-2 text-center mb-3">
              <img className="" src={portrait} alt="" height="60" width="60" />
            </div>
            <div className="col-6 col-lg-2 text-center mb-3">
              <img className="" src={portrait} alt="" height="60" width="60" />
            </div>
            <div className="col-6 col-lg-2 text-center mb-3">
              <img className="" src={portrait} alt="" height="60" width="60" />
            </div>
            <div className="col-6 col-lg-2 text-center mb-3">
              <img className="" src={portrait} alt="" height="60" width="60" />
            </div>
            <div className="col-6 col-lg-2 text-center mb-3">
              <img className="" src={portrait} alt="" height="60" width="60" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
