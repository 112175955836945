import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import level0 from "../../assets/level0.jpg";
import level1 from "../../assets/level1.jpg";
import level2 from "../../assets/level2.jpg";
import level3 from "../../assets/level3.jpg";

import level4 from "../../assets/level4.jpg";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

import moment from "moment";

function UserprofileDiv({ data }) {
  let { id } = useParams();

  const axios = useAxios();
  const levelnames = ["Newbie", "Bronze", "Silver", "Gold", "Platinum"];
  const [followersNumber, setFollowersNumber] = useState(0);
  const [followingsNumber, setFollowingsNmber] = useState(0);
  const [reviewNumber, setReviewNumber] = useState(0);
  const [LikesNumber, setLikesNumber] = useState(0);
  let [imgUser, setImgUser] = useState([
    level0,
    level1,
    level2,
    level3,
    level4,
  ]);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      const res = await axios.get("/data/follow/followers/" + id);
      const response = await axios.get("/data/follow/followings/" + id);
      const uid = await axios.get("/lreview-dashboard/" + id);
      const likesResponse = await axios.get("/likes/getbyuserid/" + id);

      if (uid.data.length > 0) {
        // setTotalRewards(12);
        setReviewNumber(
          uid.data.filter(
            (review) =>
              review.statu == "published" ||
              review.statu == "nft_created" ||
              review.statu == "failed"
          ).length
        );
      }

      if (res.data.length > 0) {
        setFollowersNumber(res.data.length);
      }

      if (response.data.length > 0) {
        setFollowingsNmber(response.data.length);
      }
      if (likesResponse.data.length > 0) {
        setLikesNumber(likesResponse.data.length);
      }
    };

    fetchReviews();
  }, []);

  return (
    <section class="py-5">
      <div class="container user_profil">
        <div class="row d-flex align-items-center">
          <div class="col-11 pt-3 mx-auto text-center">
            <img
              class="user-profil-avatar"
              src={data.profile_url || imgUser[data.level_account]}
              alt="user-avatar"
            />

            <h1>
              {data.nickname || data.first_name || data.last_name
                ? data.nickname ||
                  data.first_name +
                    " " +
                    (data.last_name ? data.last_name[0] + "." : "")
                : "User"}
            </h1>

            <p>
              {t("UserprofileMemberSince")} {moment(data.createdAt).calendar()}{" "}
              -{" "}
              <span className="badge bg-success">
                {levelnames[data.level_account]}
              </span>
            </p>
            {/* <p>
              top activity: <a href="#">hight-Tech</a> - <a href="#">Travel</a>
            </p> */}
          </div>
        </div>
        <div class="user_info text-center d-sm-flex justify-content-center gap-3">
          <div class="number_reviews">
            <strong>{reviewNumber}</strong> {t("AccountCommunityReviews")}
          </div>
          <div class="number_followers">
            <strong>{followersNumber}</strong> {t("AccountCommunityFollowers")}
          </div>
          <div class="number_following">
            <strong>{followingsNumber}</strong> {t("AccountCommunityFollowing")}
          </div>
          <div class="like">
            <strong>{LikesNumber}</strong> {t("AccountCommunityLikes")}
          </div>
          {/* <div class="comments">
            <strong>1</strong> comment
          </div>
          <div class="like">
            <strong>9</strong> likes
          </div>
          <div class="answers">
            <strong>10</strong> answers
          </div> */}
        </div>
        {/* <div class="review-filter d-flex justify-content-center gap-3 mt-3">
          <input
            type="radio"
            class="btn-check"
            name="reviews-filter"
            id="all-reviews"
            autocomplete="off"
            defaultChecked
          />
          <label class="btn btn-outline-success rounded-5" for="all-reviews">
            All reviews
          </label>
          <input
            type="radio"
            class="btn-check"
            name="reviews-filter"
            id="product-reviews"
            autocomplete="off"
          />
          <label
            class="btn btn-outline-success rounded-5"
            for="product-reviews"
          >
            Product reviews
          </label>
          <input
            type="radio"
            class="btn-check"
            name="reviews-filter"
            id="merchant-reviews"
            autocomplete="off"
          />
          <label
            class="btn btn-outline-success rounded-5"
            for="merchant-reviews"
          >
            Merchant reviews
          </label>
        </div> */}
      </div>
    </section>
  );
}

export default UserprofileDiv;
